import FormOnlyStepContainer from '../FormOnlyStep';
import * as Yup from 'yup';
import {
  DateStepProps,
  FormElementPropsType,
} from '../../../types/stepFlow.type';
import { formatAnalysisDate } from '../../../utils/helpers/analyzeTrial';
import Tooltip from '../../global/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/vectors/info.svg';
import FileUploadModal from '../../create-trial/FileUploadModal';
import { useEffect, useState } from 'react';
import { useExtractDatesFromShapefileMutation } from '../../../redux/services/analysis';
import styles from './dateStep.module.scss';
import Spinner from '../../global/Spinner';

const DateStep = ({
  dateFieldLabel,
  dateFieldName,
  initialDate,
  currentShapefile,
  children,
  ...rest
}: DateStepProps) => {
  const [extractedDates, setExtractedDates] = useState<{
    [key: string]: number;
  }>(currentShapefile?.dates_found);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [extractDatesFromShapefile, { isLoading }] =
    useExtractDatesFromShapefileMutation();
  let defaultDate;

  useEffect(() => {
    if (currentShapefile) {
      setExtractedDates(currentShapefile.dates_found);
    }
  }, [currentShapefile]);

  if (extractedDates && initialDate === undefined) {
    // get the date with the most entries found
    const sortedDates = Object.entries(extractedDates).sort(
      (a, b) => b[1] - a[1],
    );
    if (sortedDates.length > 0) {
      defaultDate = sortedDates[0][0];
    }
  }

  let inputFormatDate = '';
  if (initialDate) {
    inputFormatDate = new Date(formatAnalysisDate(initialDate))
      .toISOString()
      .split('T')[0];
  } else if (defaultDate) {
    inputFormatDate = new Date(defaultDate).toISOString().split('T')[0];
  }

  let formElements: FormElementPropsType[] = [
    {
      type: 'date',
      name: dateFieldName,
      label: dateFieldLabel,
      value: inputFormatDate,
      placeholder: 'Select a date',
      required: true,
    },
  ];

  let initialValues = {
    [dateFieldName]: inputFormatDate,
  };

  const validationSchema: Yup.ObjectSchema<any> = Yup.object({
    [dateFieldName]: Yup.string().required(),
  });

  let header = `Please enter the ${dateFieldLabel.toLowerCase()}.`;

  const handleExtractDates = async (files: File[]) => {
    if (files.length === 0) {
      return;
    }
    try {
      setErrorText(null);
      await extractDatesFromShapefile({
        shapefiles: files,
      }).then((response) => {
        if ('data' in response && response.data.data) {
          if (Object.keys(response.data.data).length === 0) {
            setErrorText('No dates were found in this shapefile.');
          } else {
            setExtractedDates(response.data.data);
          }
        } else {
          setErrorText('Unknown error extracting dates.');
        }
      });
    } catch (error) {
      setErrorText(`Error extracting dates from shapefile: ${error}`);
    }
  };

  const dateFieldMap: { [key: string]: [string, string] } = {
    planting_date: ['planting', 'a planting'],
    application_date: ['application', 'an application'],
    harvest_date: ['harvest', 'a yield'],
  };

  const uploadForm = (
    <div className={styles['date-step-upload-form']}>
      <FileUploadModal
        name={`upload_${dateFieldName}_shapefile`}
        metaName={`upload_${dateFieldName}_shapefile_meta`}
        title={`${dateFieldLabel} Shapefile`}
        fileDescription={`No potential ${dateFieldMap[dateFieldName][0]} dates were found in your existing files. 
          Select ${dateFieldMap[dateFieldName][1]} shapefile and we will attempt to extract its dates, or enter
          the date manually above.`}
        uploadAction={async (files) => {
          await handleExtractDates(files);
        }}
      />
      {isLoading && <Spinner color="brand" />}
      {errorText && (
        <p className={styles['date-step-text--error']}>{errorText}</p>
      )}
    </div>
  );

  let showUploadForm = true;

  if (extractedDates && Object.keys(extractedDates).length > 0) {
    // determine total number of dates found
    const totalDataPoints = Object.values(extractedDates).reduce(
      (sum, current) => sum + current,
      0,
    );
    formElements.push({
      element: 'radioGroup',
      name: dateFieldName,
      align: 'singleInput',
      value: inputFormatDate,
      options: Object.entries(extractedDates).map(([date, value]) => ({
        value: new Date(date).toISOString().split('T')[0],
        label: `${date} - ${value} datapoints found (${Math.round(
          (value / totalDataPoints) * 100,
        )}% of total)`,
      })),
    });

    header = `Please enter the ${dateFieldLabel.toLowerCase()} or select from the dates we found in your shapefile.`;

    showUploadForm = false;
  }

  const headerTooltip = (
    <Tooltip
      label={`We need to know this in order to properly align weather data for your field with the 
      ${
        dateFieldName === 'planting_date'
          ? 'date the crop was planted'
          : 'application date'
      } when we generate your report.`}
    >
      <span style={{ padding: '0px 5px 0px 0px' }}>{header}</span>
      <InfoIcon />
    </Tooltip>
  );

  formElements.unshift({
    element: 'sectionHeading',
    label: headerTooltip,
    align: 'horizontalPair',
  });

  return (
    <FormOnlyStepContainer
      {...rest}
      initialValues={initialValues}
      uniqueKey="select_date"
      validationSchema={validationSchema}
      formElements={formElements}
      children={showUploadForm ? [children, uploadForm] : children}
    />
  );
};

export { DateStep };
