import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import getFileExtension from 'utils/helpers/getFileExtension';
import UploadedFile from '../UploadedFile';
import styles from './uploadedFileList.module.scss';

interface UploadedFileListProps {
  name: string;
}

interface DisplayedFile {
  file: File;
  uploaded: boolean;
}

interface DisplayedFiles {
  // Required files
  dbf?: DisplayedFile | null;
  shp?: DisplayedFile | null;
  shx?: DisplayedFile | null;
  // Optional files
  cpg?: DisplayedFile | null;
  prj?: DisplayedFile | null;
  // Special case
  zip?: DisplayedFile | null;
}

const initialDisplayedFiles: DisplayedFiles = {
  // Required
  dbf: null,
  shp: null,
  shx: null,
  // Optional
  cpg: null,
  prj: null,
};

const REQUIRED_EXTENSIONS = ['dbf', 'shp', 'shx'];
const OPTIONAL_EXTENSIONS = ['cpg', 'prj'];

const UploadedFileList = ({ name }: UploadedFileListProps) => {
  const { values, setFieldValue } = useFormikContext<Record<string, any>>();
  const [displayedFiles, setDisplayedFiles] = useState<DisplayedFiles>(
    initialDisplayedFiles,
  );

  const files = useMemo(
    () => (Array.isArray(values[name]) ? (values[name] as File[]) : []),
    [values, name],
  );

  const removeFile = (filename: string): void => {
    const updatedFileList = files.filter((file) => file.name !== filename);

    const fileExtension = getFileExtension(filename);
    const emptyFile = files.length
      ? {
          file: new File(
            [''],
            files[0].name.replace(/.{0,3}$/, fileExtension),
            {
              ...files[0],
            },
          ),
          uploaded: false,
        }
      : null;

    displayedFiles[fileExtension as keyof DisplayedFiles] = emptyFile;

    setFieldValue(name, updatedFileList);
    setDisplayedFiles(displayedFiles);
  };

  useEffect(() => {
    if (files.length) {
      let updatedFiles = Object.assign({}, initialDisplayedFiles);

      if (getFileExtension(files[0].name) === 'zip') {
        updatedFiles = {
          zip: {
            file: files[0],
            uploaded: true,
          },
        };
      } else {
        for (const extension in updatedFiles) {
          const file = files.find(
            (existingFile) => getFileExtension(existingFile.name) === extension,
          );

          if (!file) {
            updatedFiles[extension as keyof DisplayedFiles] = {
              file: new File(
                [''],
                files[0].name.replace(/.{0,3}$/, extension),
                {
                  ...files[0],
                },
              ),
              uploaded: false,
            };
          } else {
            updatedFiles[extension as keyof DisplayedFiles] = {
              file,
              uploaded: true,
            };
          }
        }
      }

      setDisplayedFiles(updatedFiles);
    }
  }, [name, files]);

  if (displayedFiles.zip) {
    return (
      <ul className={styles['file-list']}>
        <UploadedFile
          file={displayedFiles.zip.file}
          isPlaceholder={!displayedFiles.zip.uploaded}
          removeFile={() => removeFile(displayedFiles.zip!.file.name)}
        />
      </ul>
    );
  }

  // Check if we have any files at all
  const hasAnyFiles = files.length > 0;
  if (!hasAnyFiles) {
    return null;
  }

  // Get lists of files for each section
  const requiredFiles = REQUIRED_EXTENSIONS.map(
    (ext) => displayedFiles[ext as keyof DisplayedFiles],
  ).filter((f): f is DisplayedFile => Boolean(f));
  const optionalFiles = OPTIONAL_EXTENSIONS.map(
    (ext) => displayedFiles[ext as keyof DisplayedFiles],
  ).filter((f): f is DisplayedFile => Boolean(f));

  // Count actual uploaded files (not placeholders) in each section
  const hasUploadedRequiredFiles = requiredFiles.some((file) => file.uploaded);
  const hasUploadedOptionalFiles = optionalFiles.some((file) => file.uploaded);

  // If we have any non-zip files, show both sections with placeholders
  const hasNonZipFiles = files.some(
    (file) => getFileExtension(file.name) !== 'zip',
  );

  return (
    <div className={styles['file-groups']}>
      {(hasUploadedRequiredFiles || hasNonZipFiles) && (
        <div className={styles['required-files']}>
          <h4 className={styles['group-header']}>Required Files</h4>
          <ul className={styles['file-list']}>
            {REQUIRED_EXTENSIONS.map((ext) => {
              const file = displayedFiles[ext as keyof DisplayedFiles];
              if (!file) return null;
              return (
                <UploadedFile
                  file={file.file}
                  key={ext}
                  isPlaceholder={!file.uploaded}
                  removeFile={() => removeFile(file.file.name)}
                />
              );
            })}
          </ul>
        </div>
      )}
      {(hasUploadedOptionalFiles || hasNonZipFiles) && (
        <div className={styles['optional-files']}>
          <h4 className={styles['group-header']}>Optional Files</h4>
          <ul className={styles['file-list']}>
            {OPTIONAL_EXTENSIONS.map((ext) => {
              const file = displayedFiles[ext as keyof DisplayedFiles];
              if (!file) return null;
              return (
                <UploadedFile
                  file={file.file}
                  key={ext}
                  isPlaceholder={!file.uploaded}
                  removeFile={() => removeFile(file.file.name)}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UploadedFileList;
