import { createPortal } from 'react-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './tooltip.module.scss';

interface TooltipProps {
  label?: React.ReactNode;
  children: React.ReactNode;
}

const Tooltip = ({ label, children }: TooltipProps): JSX.Element | null => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    top: 0,
    left: 0,
    transform: 'translateX(-50%)',
  });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipLabelRef = useRef<HTMLSpanElement>(null);

  const updateTooltipPosition = useCallback(() => {
    if (tooltipRef.current && tooltipLabelRef.current) {
      const elementRect = tooltipRef.current.getBoundingClientRect();
      const tooltipRect = tooltipLabelRef.current.getBoundingClientRect();

      const offset = 12; // Distance between element and tooltip

      let top = elementRect.top + window.scrollY - tooltipRect.height - offset;
      let left = elementRect.left + window.scrollX + elementRect.width / 2;

      // Ensure tooltip always stays above and within viewport
      if (top < 0) top = elementRect.bottom + window.scrollY + offset;
      if (left - tooltipRect.width / 2 < 0)
        left = window.scrollX + elementRect.left + tooltipRect.width / 2;
      if (left + tooltipRect.width / 2 > document.documentElement.clientWidth) {
        left = window.scrollX + elementRect.right - tooltipRect.width / 2;
      }

      setTooltipPosition({ top, left, transform: 'translateX(-50%)' });
    }
  }, []);

  useEffect(() => {
    if (tooltipVisible) {
      updateTooltipPosition();
      window.addEventListener('resize', updateTooltipPosition);
      window.addEventListener('scroll', updateTooltipPosition);
    } else {
      window.removeEventListener('resize', updateTooltipPosition);
      window.removeEventListener('scroll', updateTooltipPosition);
    }

    return () => {
      window.removeEventListener('resize', updateTooltipPosition);
      window.removeEventListener('scroll', updateTooltipPosition);
    };
  }, [tooltipVisible, updateTooltipPosition]);

  const tooltipsContainer = document.getElementById('tooltips-container');

  if (!tooltipsContainer) return null;
  if (!label) return <>{children}</>;

  return (
    <div
      className={styles['tooltip']}
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
      ref={tooltipRef}
      data-testid="tooltip"
    >
      {children}

      {createPortal(
        <span
          className={styles['tooltip__label']}
          style={{
            ...tooltipPosition,
            visibility: tooltipVisible ? 'visible' : 'hidden',
            opacity: tooltipVisible ? 1 : 0,
            position: 'absolute',
            transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
          }}
          ref={tooltipLabelRef}
          data-testid="tooltip__label"
        >
          {label}
        </span>,
        tooltipsContainer,
      )}
    </div>
  );
};

export default Tooltip;
