import { SummaryFieldProps } from '../../types/stepFlow.type';
import { ReactElement } from 'react';

export function objectToFormData(
  obj: any,
  parentKey?: string,
  formData = new FormData(),
): FormData {
  // Convert an object to FormData. Finds any arrays and duplicates the key for each element in the array.
  // This is to handle File arrays for shapefiles the way the APIs expect.
  if (obj === null) {
    return formData;
  }
  if (obj.constructor === Array) {
    obj.forEach((element: any, index: number) => {
      objectToFormData(element, parentKey, formData);
    });
  } else if (
    typeof obj === 'object' &&
    !(obj instanceof File) &&
    !(obj instanceof Date)
  ) {
    Object.keys(obj).forEach((key) => {
      let element = obj[key];
      if (element === undefined) {
        return; // skip undefined
      }
      objectToFormData(
        element,
        parentKey ? `${parentKey}.${key}` : key,
        formData,
      );
    });
  } else {
    if (!['', undefined].includes(obj)) {
      formData.append(parentKey ?? '', obj);
    }
  }
  return formData;
}

interface SummaryFieldParams {
  label: string;
  summaryValue:
    | string
    | number
    | undefined
    | null
    | File[]
    | string[]
    | ReactElement;
  unitsField?: string | number;
  customUnit?: string;
}

export function generateSummaryField(
  params: SummaryFieldParams,
): SummaryFieldProps {
  // Assemble a SummaryField from various combinations of fields.
  let value;
  if (Array.isArray(params.summaryValue)) {
    if (params.summaryValue.length === 0) {
      // replace empty array with None
      value = 'None';
    }
    // Check if first element is a string
    else if (typeof params.summaryValue[0] === 'string') {
      // If it's a string, just use the string directly
      value = params.summaryValue[0];
    } else {
      // If it's not a string, then it's a File, get the filename
      value = (params.summaryValue[0] as File).name.split('.')[0];
    }
  } else if (
    typeof params.summaryValue !== 'number' &&
    typeof params.summaryValue !== 'object' &&
    [undefined, '', null, 'none'].includes(params.summaryValue)
  ) {
    value = 'None';
  } else if (params.unitsField !== undefined) {
    value = `${params.summaryValue || 0} ${
      params.customUnit || (params.unitsField === 'm' ? 'meters' : 'feet')
    }`;
  } else if (params.customUnit !== undefined) {
    value = `${params.summaryValue} ${params.customUnit}`;
  } else if (typeof params.summaryValue === 'object') {
    // pass the shapefile link straight through
    value = params.summaryValue;
  } else {
    value = `${params.summaryValue}`;
  }

  return {
    element: 'summaryField',
    label: params.label,
    value: value || 'None',
  };
}

export const formatAnalysisDate = (
  dateValue: string | number | undefined | null,
): string => {
  // provide consistent date formatting across all Analysis pages

  if (dateValue === undefined || dateValue === null || dateValue === '') {
    return 'Not provided';
  }

  const formatter = new Intl.DateTimeFormat(undefined, { dateStyle: 'medium' });

  try {
    let date: Date;

    if (typeof dateValue === 'number') {
      // Sanity check for reasonable timestamp range (2000-2100)
      if (dateValue < 946684800 || dateValue > 4102444800) {
        return 'Invalid date';
      }
      // UTC timestamp in seconds - create date in UTC and adjust for local timezone
      date = new Date(0);
      date.setUTCSeconds(dateValue);
    } else if (dateValue.includes('GMT')) {
      // Handle "Wed, 31 Dec 2025 00:00:00 GMT" format as used by design trial DB
      date = new Date(dateValue + ' UTC'); // Force UTC parsing
    } else {
      // ISO date string (YYYY-MM-DD)
      const [year, month, day] = dateValue.split('-').map(Number);

      // We have to validate the date components here ourselves, because Javascript.
      // It turns out if you do Date.UTC(year, month, day) with a month/day out of range,
      // it will happily return a date *by adding the rollover days or months*.
      // So, Date.UTC(2025, 13, 32) is 2026-03-04. This hurts my brain, but OK.

      // Basic range validation
      if (
        month < 1 ||
        month > 12 ||
        day < 1 ||
        day > 31 ||
        year < 2000 ||
        year > 2100
      ) {
        return 'Invalid date';
      }

      // Days in month validation (including leap years)
      const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (month === 2) {
        // Leap year check
        const isLeapYear =
          (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
        if (day > (isLeapYear ? 29 : 28)) {
          return 'Invalid date';
        }
      } else if (day > daysInMonth[month - 1]) {
        return 'Invalid date';
      }

      // Create date at noon UTC to avoid timezone issues
      date = new Date(Date.UTC(year, month - 1, day, 12));
    }

    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }

    return formatter.format(date);
  } catch (e) {
    return 'Invalid date';
  }
};
