import Checkbox from 'components/global/Checkbox';
import FormElement from 'components/global/FormElement';
import { useAppSelector } from 'redux/hooks';
import { Form, useFormikContext } from 'formik';
import getOptions from 'utils/helpers/getOptions';

const TestAreasForm = () => {
  const { values, setFieldValue } = useFormikContext<Record<string, any>>();
  const { trial } = useAppSelector((state) => state.createTrial);

  const isPlot = values.trial_type === 'plot';

  return (
    <Form>
      <FormElement
        name="trial_type"
        direction="row"
        element="select"
        label="Plot trial or strip trial:"
        options={getOptions(['plot', 'strip'])}
      />

      {values.trial_type ? (
        <>
          <FormElement
            name="width_ft"
            direction="row"
            label={`${isPlot ? 'Plot' : 'Strip'} width:`}
            rightElement="ft"
          />

          {isPlot && (
            <FormElement
              name="length_ft"
              direction="row"
              label="Plot length:"
              rightElement="ft"
            />
          )}

          <FormElement
            name="buffer_ft"
            direction="row"
            label="Trial buffer width:"
            rightElement="ft"
            tooltip="Width of region extending in from field boundary where no test plots or strips are placed. Not testing near the field boundary reduces edge effects. Typically around 100 ft."
          />

          {!trial.trial_boundary_is_field_boundary && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Checkbox
                id="apply-buffer"
                label="Apply trial boundary buffer to field boundary (typical)"
                tooltip="If unchecked, the trial boundary buffer will be applied to the trial boundary, so test plots will not be allowed within the trial buffer width of the trial boundary."
                checked={values.apply_trial_buffer_to_field_boundary}
                onChange={() =>
                  setFieldValue(
                    'apply_trial_buffer_to_field_boundary',
                    !values.apply_trial_buffer_to_field_boundary,
                  )
                }
              />
            </div>
          )}
        </>
      ) : null}
    </Form>
  );
};

export default TestAreasForm;
