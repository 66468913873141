import { FileTypes } from '../../types/global.type';

const fileTypes: FileTypes = {
  png: {
    description: 'PNG Image',
    accept: { 'image/png': ['.png'] },
  },
  pdf: {
    description: 'PDF Document',
    accept: { 'application/pdf': ['.pdf'] },
  },
  zip: {
    description: 'ZIP Archive',
    accept: { 'application/zip': ['.zip'] },
  },
  json: {
    description: 'JSON File',
    accept: { 'application/json': ['.json'] },
  },
};

const saveFile = async (url: string) => {
  if (typeof url !== 'string') return;

  try {
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    const fileExtension = pathname.split('.').pop()?.toLowerCase();

    const isPdfOrImage = ['pdf', 'jpg', 'jpeg', 'png'].includes(
      fileExtension || '',
    );

    if (isPdfOrImage) {
      window.open(url, '_blank');
      return;
    }

    if (!('showSaveFilePicker' in window)) return download(url);

    let fetchError = false;
    let res = await window.fetch(url);
    let blob: Blob | void = undefined;

    if (res) blob = await res.blob();
    else fetchError = true;

    if (!blob || fetchError) return download(url);

    const suggestedFilename = pathname.substring(pathname.lastIndexOf('/') + 1);
    const extension =
      suggestedFilename.split('.').pop()?.toLowerCase() || 'txt';

    const options = {
      suggestedName: suggestedFilename,
      types: fileTypes[extension] ? [fileTypes[extension]] : [],
    };

    const fileHandle = await (window as any).showSaveFilePicker(options);
    if (!fileHandle) return download(url);

    const writer = await fileHandle.createWritable();
    await writer.write(blob);
    await writer.close();
  } catch (error) {
    download(url);
  }
};

const download = (url: string) => {
  const a: HTMLAnchorElement = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  const urlObj = new URL(url);
  const pathname = urlObj.pathname;
  const fileExtension = pathname.split('.').pop()?.toLowerCase();
  const isImageOrPdf =
    fileExtension && ['jpg', 'jpeg', 'png', 'pdf'].includes(fileExtension);

  if (isImageOrPdf) {
    a.target = '_blank';
  }

  a.href = url;
  a.download = pathname.substring(pathname.lastIndexOf('/') + 1);
  a.click();

  window.URL.revokeObjectURL(url);
  a.remove();
};

export default saveFile;
