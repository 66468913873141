import { useCallback, useEffect, useState } from 'react';
import { updateAsAppliedFlowStep } from '../../../redux/slices/analyzeTrial';
import { StepContent } from '../../step-flow/CreateStepContent';
import {
  AsAppliedSummaryStepDefinition,
  FlowStep,
  FormAndImageStepDefinition,
  RepsToRemoveStepDefinition,
  ShapefileColumnMapStepDefinition,
  UploadAndPreviewShapefileStepDefinition,
} from '../../../types/stepFlow.type';
import FormAndImageStep from '../../step-flow/FormAndImageStep';
import * as Yup from 'yup';
import { useAppSelector } from '../../../redux/hooks';
import styles from './asAppliedFlow.module.scss';
import getOption from '../../../utils/helpers/getOption';
import { titleCase } from '../../../utils/helpers/capitalizationUtils';
import UploadAndPreviewShapefileStep from '../../step-flow/UploadAndPreviewShapefileStep';
import ShapefileColumnMapStep, {
  getShapefileColumnMapFields,
  getShapefileColumnMapInitialValues,
  getShapefileColumnMapValidator,
} from '../ShapefileColumnMapStep';
import { fileValidator } from '../../../validators/createTrialSchemas';
import AsAppliedSummaryStep from '../AsAppliedSummaryStep';
import { useSearchParams } from 'react-router-dom';
import RepsToRemoveStep from '../RepsToRemoveStep';
import { useGCPFiles } from '../ShapefileImagePreview';
import { DateStep } from '../../step-flow/DateStep';
import { AsAppliedSubmitStructure } from '../../../types/analyzeTrial.type';
import { useFetchDesignImageQuery } from '../../../redux/services/analysis';
import loadingImage from '../../../assets/images/loading-image.png';

const getInitialDate = (
  dateType: string,
  savedAsAppliedData: any,
  asAppliedInProgress: Record<string, any> | AsAppliedSubmitStructure,
) => {
  if (dateType === 'planting_date') {
    return (
      savedAsAppliedData?.planting_date ||
      asAppliedInProgress.planting_date ||
      undefined
    );
  } else if (dateType === 'application_date') {
    return (
      savedAsAppliedData?.application_date ||
      asAppliedInProgress.application_date ||
      undefined
    );
  } else {
    throw new Error(
      'Invalid dateType, must be planting_date or application_date',
    );
  }
};

const AsAppliedFlow = () => {
  const trialDesign = useAppSelector((state) => state.analyzeTrial.trialDesign);
  const [searchParams] = useSearchParams();
  const [fieldResultId, setFieldResultId] = useState<string | null>(
    searchParams.get('id'),
  );
  const [savedAsAppliedData, setSavedAsAppliedData] = useState<any>(null);
  const [isAdditional, setIsAdditional] = useState<boolean | null>(null);
  const fieldResults = useAppSelector(
    (state) => state.analyzeTrial.fieldResults,
  );
  const asAppliedInProgress = useAppSelector(
    (state) => state.analyzeTrial.asAppliedFlow.inProgress,
  );
  const [trialType, setTrialType] = useState(
    trialDesign.planning_trial_type === 'Planting'
      ? 'planting'
      : 'non-planting',
  );
  const [firstDateParams, setFirstDateParams] = useState(
    trialDesign.planning_trial_type === 'Planting'
      ? { dateFieldLabel: 'Planting Date', dateFieldName: 'planting_date' }
      : {
          dateFieldLabel: 'Application Date',
          dateFieldName: 'application_date',
        },
  );

  const [fieldResultsType, setFieldResultsType] = useState('as-planted');

  const { data: designImage } = useFetchDesignImageQuery(
    trialDesign?.shapefile_preview_url as string,
    {
      skip: !trialDesign?.shapefile_preview_url,
    },
  );

  const updateTrialType = (data: any) => {
    setTrialType(data.experiment_type);
  };

  const MatchFieldResult = useCallback(() => {
    // find the field result that matches the one we're editing, if any, and determine whether it
    // is an additional result (i.e. second pass) in order to omit buffer and planting date steps
    for (let i = 0; i < fieldResults.length; i++) {
      if (fieldResults[i].field_result_id === fieldResultId) {
        return {
          asAppliedData: fieldResults[i].as_applied_data,
          isAdditional: fieldResults[i]?.is_additional || null,
        };
      }
    }
    return { asAppliedData: null, isAdditional: null };
  }, [fieldResults, fieldResultId]);

  useEffect(() => {
    if (fieldResultId) {
      const { asAppliedData, isAdditional } = MatchFieldResult();
      setSavedAsAppliedData(asAppliedData);
      setIsAdditional(isAdditional || fieldResultId === 'additional');
    }
  }, [fieldResultId, MatchFieldResult]);

  useEffect(() => {
    if (trialType === 'planting') {
      setFieldResultsType('as-planted');
      setFirstDateParams({
        dateFieldLabel: 'Planting Date',
        dateFieldName: 'planting_date',
      });
    } else {
      setFieldResultsType('as-applied');
      setFirstDateParams({
        dateFieldLabel: 'Application Date',
        dateFieldName: 'application_date',
      });
    }
  }, [trialType]);

  const asAppliedFlowSteps: FlowStep[] = [
    {
      container: FormAndImageStep,
      heading: 'Confirm the experiment type',
      props: {
        formElements: [
          {
            name: 'experiment_type',
            value: trialType,
            element: 'radioGroup',
            radioGroupHeader: 'Experiment Type',
            options: [
              getOption({
                value: 'planting',
                tooltip:
                  'Typically a seeding rate or hybrid trial. We use this to determine we need to ' +
                  'analyze your as-planted data instead of as-applied data.',
              }),
              getOption({
                value: 'non-planting',
                tooltip:
                  'Typically a pest control or fertilizer trial. We use this to determine we need to ' +
                  'analyze your as-applied data instead of as-planted data.',
              }),
            ],
          },
        ],
        imageUrl: designImage?.image || loadingImage,
        initialValues: {
          experiment_type: trialType,
        },
        uniqueKey: 'experiment_type',
        validationSchema: Yup.object({
          experiment_type: Yup.string().required(
            'You must select an experiment type.',
          ),
        }),
        valuesCallback: updateTrialType,
      },
    } as FormAndImageStepDefinition,
    {
      container: UploadAndPreviewShapefileStep,
      heading: `${titleCase(fieldResultsType)} data`,
      props: {
        currentShapefile: savedAsAppliedData?.current_shapefile,
        fieldBoundaryUrl: trialDesign.field_boundary_file_url,
        fieldResultId: fieldResultId,
        formElements: [],
        imageUrl:
          savedAsAppliedData?.raw_preview_image?.signed_url ||
          designImage?.image ||
          loadingImage,
        initialValues: {
          as_applied_shapefiles:
            asAppliedInProgress.as_applied_shapefiles || [],
        },
        setFieldResultId: setFieldResultId,
        shapefileType: fieldResultsType,
        trialBoundaryUrl: trialDesign.trial_boundary_file_url,
        trialId: trialDesign.id,
        uniqueKey: 'as_applied_shapefiles',
        validationSchema: savedAsAppliedData?.current_shapefile
          ? null
          : Yup.object({
              as_applied_shapefiles: fileValidator(fieldResultsType),
            }),
      },
    } as UploadAndPreviewShapefileStepDefinition,
    {
      container: ShapefileColumnMapStep,
      heading: `Identify ${titleCase(fieldResultsType)} data columns`,
      props: {
        fieldBoundaryUrl: trialDesign.field_boundary_file_url,
        fieldResultId: fieldResultId,
        formElements: getShapefileColumnMapFields({
          savedData: savedAsAppliedData,
          shapefileGeometry:
            savedAsAppliedData?.current_shapefile?.geometry_type,
          shapefileKeyColumnLabel: `${titleCase(fieldResultsType)} rate`,
          shapefileType: 'as-applied',
        }),
        imageUrl:
          savedAsAppliedData?.scaled_preview_image?.signed_url ||
          savedAsAppliedData?.raw_preview_image?.signed_url,
        initialValues: getShapefileColumnMapInitialValues({
          savedData: savedAsAppliedData,
          shapefileGeometry:
            savedAsAppliedData?.current_shapefile?.geometry_type,
          shapefileType: 'as-applied',
          trialUnit: fieldResults[0]?.as_applied_data?.rate_units
            ? fieldResults[0].as_applied_data.rate_units
            : trialDesign?.application_units ?? 'gal',
        }),
        savedData: savedAsAppliedData,
        setFieldResultId: setFieldResultId,
        shapefileGeometry: savedAsAppliedData?.current_shapefile?.geometry_type,
        shapefileType: fieldResultsType,
        trialBoundaryUrl: trialDesign.trial_boundary_file_url,
        trialId: trialDesign.id,
        uniqueKey: 'as_applied_column_map',
        uploadedFiles: asAppliedInProgress.as_applied_shapefiles || [],
        useGCPFiles: useGCPFiles(
          savedAsAppliedData,
          asAppliedInProgress.as_applied_shapefiles,
          'ShapefileColumnMapStep',
        ),
        validationSchema: getShapefileColumnMapValidator({
          shapefileType: 'as-applied',
          shapefileGeometry:
            savedAsAppliedData?.current_shapefile?.geometry_type,
          trialUnit: fieldResults[0]?.as_applied_data?.rate_units
            ? fieldResults[0].as_applied_data.rate_units
            : trialDesign?.application_units ?? 'gal',
        }),
      },
    } as ShapefileColumnMapStepDefinition,
    {
      container: RepsToRemoveStep,
      heading: `${titleCase(fieldResultsType)} data quality control`,
      props: {
        fieldBoundaryUrl: trialDesign.field_boundary_file_url,
        trialBoundaryUrl: trialDesign.trial_boundary_file_url,
        fieldResultId: fieldResultId,
        imageUrl:
          savedAsAppliedData?.scaled_with_plots_preview_image?.signed_url ||
          savedAsAppliedData?.scaled_preview_image?.signed_url ||
          savedAsAppliedData?.raw_preview_image?.signed_url,
        inProgressExecution: asAppliedInProgress,
        prescriptionParams:
          trialDesign.treatment_file_url !== null
            ? { prescriptionUrl: trialDesign.treatment_file_url }
            : {
                transactionId: trialDesign.transaction_id,
                fieldName: trialDesign.field.field_name,
              },
        savedData: savedAsAppliedData,
        setFieldResultId: setFieldResultId,
        shapefileType: 'as-applied',
        trialId: trialDesign.id,
        uniqueKey: 'as_applied_reps_to_remove',
        uploadedFiles: asAppliedInProgress.as_applied_shapefiles || [],
        useGCPFiles: useGCPFiles(
          savedAsAppliedData,
          asAppliedInProgress.as_applied_shapefiles,
          'RepsToRemoveStep',
        ),
      },
    } as RepsToRemoveStepDefinition,
    {
      container: DateStep,
      heading: firstDateParams.dateFieldLabel,
      props: {
        currentShapefile: savedAsAppliedData?.current_shapefile,
        dateFieldLabel: firstDateParams.dateFieldLabel,
        dateFieldName: firstDateParams.dateFieldName,
        initialDate: getInitialDate(
          firstDateParams.dateFieldName,
          savedAsAppliedData,
          asAppliedInProgress,
        ),
        key: 'as_applied_date',
      },
    },
    {
      container: AsAppliedSummaryStep,
      heading: `${titleCase(fieldResultsType)}: Review and Save`,
      props: {
        currentShapefile: savedAsAppliedData?.current_shapefile,
        fieldResultId: fieldResultId,
        imageUrl:
          savedAsAppliedData?.scaled_with_plots_preview_image?.signed_url ||
          savedAsAppliedData?.scaled_preview_image?.signed_url ||
          savedAsAppliedData?.raw_preview_image?.signed_url,
        inProgressExecution: asAppliedInProgress,
        setSavedData: setSavedAsAppliedData,
        shapefileGeometry: savedAsAppliedData?.current_shapefile?.geometry_type,
        shapefileType: titleCase(fieldResultsType),
        trialId: trialDesign.id,
        trialType: trialType,
        uniqueKey: 'as_applied_summary',
        isAdditional: isAdditional,
      },
    } as AsAppliedSummaryStepDefinition,
  ];

  if (trialType !== 'planting' && !isAdditional) {
    const plantingDateStep: FlowStep = {
      heading: 'Planting Date',
      container: DateStep,
      props: {
        dateFieldLabel: 'Planting Date',
        dateFieldName: 'planting_date',
        currentShapefile: null,
        initialDate: getInitialDate(
          'planting_date',
          savedAsAppliedData,
          asAppliedInProgress,
        ),
        key: 'planting_date',
      },
    };
    asAppliedFlowSteps.splice(6, 0, plantingDateStep);
  }

  const [activeIndex, setActiveIndex] = useState(0);
  const { heading, subheading, container, props } =
    asAppliedFlowSteps[activeIndex];

  if (Object.keys(trialDesign).length === 0) {
    return (
      <div
        data-testid="as-applied-flow-trial-design-missing-error"
        className={styles['as-applied-flow-error']}
      >
        <p>
          <b>The current page was loaded without a trial design.</b>
        </p>
        <p>
          This is probably because the URL was loaded directly, rather than from
          an Analysis & Results page. Please navigate from your list of field
          trials to view the analysis and results for a specific trial; from
          there you may click "Edit As-Applied Data" or "Upload As-Applied Data"
          to correctly load this page.
        </p>
        <p>
          If you came here from a link elsewhere on the site, please contact
          FarmTest for assistance.
        </p>
      </div>
    );
  }

  return (
    <StepContent
      heading={heading}
      subheading={subheading}
      superheading={`Analyze trial`}
      Container={container}
      props={props}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      updateStateFunction={updateAsAppliedFlowStep}
      stateSelector={(state) => state.analyzeTrial.asAppliedFlow}
      flowSteps={asAppliedFlowSteps}
    />
  );
};

export default AsAppliedFlow;
