import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateStep } from 'redux/slices/createTrial';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { placePlotsSchema } from 'validators/createTrialSchemas';
import { useCreateTrialMutation } from 'redux/services/workspace';
import { useGuestCreateTrialMutation } from 'redux/services/guest';
import { createTrialSteps, guestCreateTrialSteps } from 'utils/createTrial';
import AuthFooterRedirect from 'components/auth/AuthFormWrapper/subcomponents/AuthFooterRedirect';
import useAuth from 'hooks/useAuth';
import useStorage from 'hooks/useStorage';
import saveFile from 'utils/helpers/saveFile';
import Button from 'components/global/Button';
import PurchaseShapefile from './PurchaseShapefile';
import splitString from 'utils/helpers/splitString';
import getErrorMessage from 'utils/helpers/getErrorMessage';
import PlacePlotsForm from 'components/create-trial/PlacePlotsForm';
import toast from 'react-hot-toast';
import { Routes } from 'types/global.type';

import { StepProps } from '../../types/stepFlow.type';

const PlacePlotsContainer = ({ children }: StepProps) => {
  const { trial } = useAppSelector((state) => state.createTrial);
  const [previewUrl, setPreviewUrl] = useState(trial.abline_preview);
  const [planningSummaryUrl, setPlanningSummaryUrl] = useState('');
  const [lsdData, setLsdData] = useState('');
  const [
    createTrial,
    { data: savedTrial, isLoading: trialLoading, error: trialError },
  ] = useCreateTrialMutation();
  const [
    previewTrial,
    {
      data: trialPreview,
      isLoading: trialPreviewLoading,
      error: trialPreviewError,
    },
  ] = useGuestCreateTrialMutation();
  const [isImageLoading, setIsImageLoading] = useState(false);
  const { addStorageValue, removeStorageValue } = useStorage();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (savedTrial) {
      setIsImageLoading(true);
      setPreviewUrl(savedTrial.previewUrl);
      setPlanningSummaryUrl(savedTrial.data.plan_summary_file_url || '');
      addStorageValue('createTrialResponse', savedTrial, 'session');
    }
  }, [savedTrial, trialError, addStorageValue]);

  useEffect(() => {
    if (trialPreview) {
      setIsImageLoading(true);
      setPreviewUrl(trialPreview.previewUrl);
      setPlanningSummaryUrl(trialPreview.planningSummaryUrl);
    }
  }, [trialPreview]);

  const isFileTypeValue = (
    formValues: Record<string, any>,
    value: 'demo' | 'trial',
  ) => {
    return (
      formValues.field_boundary_gdf === value || formValues.abline_gdf === value
    );
  };

  const getFormData = (values: Record<string, any>): FormData => {
    let formData = new FormData();
    values.seed = 1;
    values.control_index = 0;
    values.offset = 0;

    if (!isLoggedIn) {
      values.product = 'Test product';
      values.field_name = 'Test field';
    }

    const defaultFormKeys = [
      'business_name',
      'farm_name',
      'product',
      'crop',
      'variety',
      'length_ft',
      'width_ft',
      'buffer_ft',
      'seed',
      'field_name',
      'application_units',
      'nominal_rate',
      'nominal_label',
      'control_index',
      'is_microbe_test',
      'start_date',
      'end_date',
      'offset',
      'planning_trial_type',
      'planning_trial_subtype',
      'apply_trial_buffer_to_field_boundary',
      'trial_equipment',
      'reps_to_remove_from_design',
    ];
    const percentKeys = ['nominal_management_prop', 'prop_keep'];

    for (const key of defaultFormKeys) {
      if (typeof values[key] === 'number' || values[key]) {
        formData.append(key, String(values[key]).trim());
      }
    }
    for (const key of percentKeys) {
      formData.append(key, String(values[key] / 100));
    }

    if (values.test_nutrients?.length) {
      formData.append('test_nutrients', values.test_nutrients.join(','));
    }

    formData.append('trial_type', `${values.trial_type}s`);
    formData.append(
      'treatment_rates',
      splitString(values.treatment_rates, ', ').join(','),
    );

    if (values.treatment_labels && values.treatment_labels !== 'Use rates') {
      formData.append(
        'treatment_labels',
        splitString(values.treatment_labels, ', ').join(','),
      );
    } else {
      formData.append('treatment_labels', 'Use rates');
    }

    if (isFileTypeValue(values, 'trial')) {
      formData.append('file_source', 'trial');
      formData.append('trial_id', values.trialId);
    } else {
      formData.append('file_source', 'transaction');
      formData.append('transaction_id', values.transactionId);
    }
    return formData;
  };

  const updateLocalTrial = (values: Record<string, any>) => {
    dispatch(
      updateStep({
        index:
          (isLoggedIn ? createTrialSteps : guestCreateTrialSteps).length - 1,
        data: values,
      }),
    );
  };

  return (
    <Formik
      initialValues={{
        prop_keep: '',
        nominal_management_prop: '',
        lsd: '',
      }}
      onSubmit={(values) => {
        updateLocalTrial(values);
        previewTrial(getFormData({ ...trial, ...values })).then((response) => {
          if ('data' in response) {
            const lsdPercent = response.data.lsdPercent;
            setLsdData(String(lsdPercent));
          }
        });
      }}
      validationSchema={placePlotsSchema}
      validateOnMount
    >
      {({ values }) => (
        <>
          <PlacePlotsForm
            error={getErrorMessage(trialPreviewError || trialError)}
            isLoading={trialPreviewLoading || isImageLoading}
            imageUrl={previewUrl}
            updatedLsdData={lsdData}
            onImageLoad={() => setIsImageLoading(false)}
            isSummaryAvailable={planningSummaryUrl !== ''}
          >
            <PurchaseShapefile
              getTrialData={() => {
                updateLocalTrial(values);
                return getFormData({ ...trial, ...values });
              }}
              disablePurchase={
                values.nominal_management_prop === '' || values.prop_keep === ''
              }
            />

            <Button
              action="button"
              variant="brand"
              isDisabled={planningSummaryUrl === ''}
              onClick={() => {
                if (isLoggedIn && !savedTrial?.data?.plan_summary_file_url) {
                  updateLocalTrial(values);
                  createTrial(getFormData({ ...trial, ...values }));
                }
                if (planningSummaryUrl?.match(/^(http|https):\/\/[^ "]+$/)) {
                  saveFile(planningSummaryUrl);
                } else {
                  const errorMessage =
                    'We could not generate the planning summary for this trial. Please contact us for further assistance.';
                  toast.loading(errorMessage, {
                    duration: 5000,
                  });
                }
              }}
              isLoading={Boolean(trialLoading && isLoggedIn)}
            >
              View Summary
            </Button>

            <Button
              action="button"
              variant="brand"
              onClick={() => {
                if (savedTrial?.previewUrl || !isLoggedIn) {
                  removeStorageValue('createTrialResponse', 'session');
                  navigate(0);
                } else {
                  updateLocalTrial(values);
                  createTrial(getFormData({ ...trial, ...values }));
                }
              }}
              isLoading={trialLoading}
            >
              {savedTrial?.previewUrl || !isLoggedIn ? 'Create new' : 'Save'}
            </Button>
            {!isLoggedIn && trialPreview?.planningSummaryUrl && (
              <div style={{ width: '100%', fontWeight: 'bolder' }}>
                <AuthFooterRedirect
                  {...{
                    description:
                      'If you like your trial shapefile and want to purchase it, first',
                    cta: 'create an account',
                    path: Routes.signUp,
                  }}
                />
              </div>
            )}
          </PlacePlotsForm>
          {children}
        </>
      )}
    </Formik>
  );
};

export default PlacePlotsContainer;
