import {
  AnalysisSummaryProps,
  SectionHeadingProps,
  SummaryFieldProps,
} from '../../../types/stepFlow.type';
import * as Yup from 'yup';
import analysisApi, {
  useSubmitAnalysisMutation,
} from '../../../redux/services/analysis';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'types/global.type';
import React from 'react';
import {
  generateSummaryField,
  formatAnalysisDate,
} from '../../../utils/helpers/analyzeTrial';
import FormOnlyStep from '../../step-flow/FormOnlyStep';
import { AnalysisSubmitStructure } from '../../../types/analyzeTrial.type';
import { updateAnalyses } from '../../../redux/slices/analyzeTrial';

const treatmentsToSummaryFields = (
  inProgressExecution: AnalysisSubmitStructure,
) => {
  const summaryFields: Array<SectionHeadingProps | SummaryFieldProps> = [];

  // Find all treatment_rate_X and treatment_label_X
  for (let i = 0; ; i++) {
    const rateKey = `treatment_rate_${i}`;
    const labelKey = `treatment_label_${i}`;

    if (!(rateKey in inProgressExecution)) {
      break;
    }

    const label =
      labelKey in inProgressExecution
        ? `Treatment ${i + 1} - ${inProgressExecution[labelKey]}`
        : `Treatment ${i + 1}`;
    const summaryValue = inProgressExecution[rateKey];

    summaryFields.push(
      generateSummaryField({
        label: label,
        summaryValue: summaryValue,
      }),
    );
  }

  // Process nominal_rate and nominal_label
  if ('nominal_rate' in inProgressExecution) {
    const label =
      'nominal_label' in inProgressExecution
        ? `Nominal Rate - ${inProgressExecution['nominal_label']}`
        : 'Nominal Rate';
    const summaryValue = inProgressExecution['nominal_rate'];

    summaryFields.push(
      generateSummaryField({
        label: label,
        summaryValue: summaryValue,
      }),
    );
  }

  return summaryFields;
};

const AnalysisSummaryStep = ({
  inProgressExecution,
  analysisId,
  trialId,
  cropValueUnits,
  trialDesign,
  ...rest
}: AnalysisSummaryProps) => {
  const navigate = useNavigate();
  const [submitTrialDesign] = analysisApi.useSubmitTrialDesignMutation();

  inProgressExecution = inProgressExecution as AnalysisSubmitStructure;
  let summaryFields: Array<SectionHeadingProps | SummaryFieldProps> = [
    {
      element: 'sectionHeading',
      label:
        'Please confirm this analysis configuration is correct, then click Save.',
    },
    generateSummaryField({
      label: 'Crop',
      summaryValue: inProgressExecution.analysis_crop,
    }),
    generateSummaryField({
      label: 'Variety',
      summaryValue: inProgressExecution.analysis_variety,
    }),
    generateSummaryField({
      label: 'Crop Price',
      summaryValue: inProgressExecution.crop_price_dollars_per_crop_unit,
      customUnit: cropValueUnits,
    }),
    generateSummaryField({
      label: 'Product',
      summaryValue: inProgressExecution.product_name,
    }),
    generateSummaryField({
      label: 'Product Price',
      summaryValue: inProgressExecution.product_price_dollars_per_gal,
      customUnit: '$/gal',
    }),
  ];

  if (inProgressExecution.is_tank_mix) {
    summaryFields = summaryFields.concat([
      generateSummaryField({
        label: 'Product is in Tank Mix',
        summaryValue: 'Yes',
      }),
      generateSummaryField({
        label: 'Tank Mix Percentage',
        summaryValue: inProgressExecution.tank_mix_percentage,
        customUnit: '%',
      }),
      generateSummaryField({
        label: 'Tank Mix Description',
        summaryValue: inProgressExecution.tank_mix_description,
      }),
    ]);
  }

  if (inProgressExecution.extra_pass) {
    summaryFields = summaryFields.concat([
      generateSummaryField({
        label: 'Extra Pass',
        summaryValue: 'Yes',
      }),
      generateSummaryField({
        label: 'Application Price',
        summaryValue: inProgressExecution.application_price_dollars_per_acre,
        customUnit: '$/acre',
      }),
    ]);
  }

  // add treatment rates
  summaryFields = summaryFields.concat(
    treatmentsToSummaryFields(inProgressExecution),
  );

  // add static rate if it exists
  if (inProgressExecution.static_rate) {
    summaryFields.push(
      generateSummaryField({
        label: 'Static Rate',
        summaryValue: inProgressExecution.static_rate,
      }),
    );

    // If we have a static rate application date, show it too
    if (inProgressExecution.static_rate_application_date) {
      summaryFields.push(
        generateSummaryField({
          label: 'Static Rate Application Date',
          summaryValue: formatAnalysisDate(
            inProgressExecution.static_rate_application_date,
          ),
        }),
      );
    }
  }

  // Add plot buffer fields
  summaryFields = summaryFields.concat([
    generateSummaryField({
      label: 'Plot Length',
      summaryValue: inProgressExecution.plot_length,
      unitsField: inProgressExecution.plot_buffer_length_units,
    }),
    generateSummaryField({
      label: 'Plot Buffer Length',
      summaryValue: inProgressExecution.plot_buffer_length,
      unitsField: inProgressExecution.plot_buffer_length_units,
    }),
    generateSummaryField({
      label: 'Plot Width',
      summaryValue: inProgressExecution.plot_width,
      unitsField: inProgressExecution.plot_buffer_width_units,
    }),
    generateSummaryField({
      label: 'Plot Buffer Width',
      summaryValue: inProgressExecution.plot_buffer_width,
      unitsField: inProgressExecution.plot_buffer_width_units,
    }),
  ]);

  // continue with other fields
  summaryFields = summaryFields.concat([
    generateSummaryField({
      label: 'Yield Difference',
      summaryValue: inProgressExecution.two_sided_yield_diff
        ? 'Two Sided'
        : 'One Sided',
    }),
    generateSummaryField({
      label: 'False Positive Rate',
      summaryValue: inProgressExecution.false_positive_rate,
      customUnit: '%',
    }),
  ]);

  // this needs to accept data, since FormOnlyStep calls it with data
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submitAnalysisCallback = (data: any) => {
    const analysisId = data.data.data.analysis_id;
    submitTrialDesign({ analysis_id: analysisId, trial: trialDesign });
    updateAnalyses([data.data.data]);
    navigate(`${Routes.analyzeTrial}?trial-id=${trialId}`);
  };

  return (
    <FormOnlyStep
      {...rest}
      onSubmitMutationRef={useSubmitAnalysisMutation}
      successfulSubmitCallback={submitAnalysisCallback}
      formElements={summaryFields}
      initialValues={{
        ...inProgressExecution,
        analysis_id: analysisId,
        trial_id: trialId,
      }}
      validationSchema={Yup.object()}
      submitButtonText={{
        default: 'Save',
        submitting: 'Saving...',
      }}
    />
  );
};

export default AnalysisSummaryStep;
