import * as Yup from 'yup';
import Lazy from 'yup/lib/Lazy';
import splitString from 'utils/helpers/splitString';
import { OptionalArraySchema } from 'yup/lib/array';
import { capitalizeFirstLetter } from 'utils/helpers/capitalizationUtils';

export const fileValidator = (
  name: string,
  when?: { key: string; expectedValue: any },
): Lazy<Yup.StringSchema | OptionalArraySchema<any>> => {
  return Yup.lazy((value) => {
    if (Array.isArray(value)) {
      // only one file is required if zip file detected
      if (value.some((file) => file.name.endsWith('.zip'))) {
        return Yup.array()
          .min(1, `Minimum of 1 ${name} file required.`)
          .max(1, `Maximum of 1 ${name} file allowed.`);
      }
      const requiredSchema = Yup.array().min(
        3,
        `Minimum of 3 ${name} shapefile components are required: .shp, .shx, .dbf`,
      );

      if (when) {
        const { key, expectedValue } = when;

        return Yup.array().when(key, {
          is: (value: any) => {
            return value === expectedValue;
          },
          then: requiredSchema,
        });
      }

      return requiredSchema;
    }

    return Yup.string().oneOf(['demo', 'trial']);
  });
};

const createTrialSchema = Yup.object().shape({
  abline_gdf: fileValidator('AB line'),
  field_boundary_gdf: fileValidator('field boundary'),
  product: Yup.string()
    .required('Product name is required.')
    .matches(
      /^[ A-Za-z0-9_%'.,-]*$/,
      'Invalid product name character(s). Valid product name characters are: a-z, A-Z, 0-9, _, -, %, apostrophe, space, period, comma',
    ),
  nominal_management_prop: Yup.number()
    .required('Minimum field area percentage is required.')
    .min(
      0,
      'Invalid Minimum field area percentage to have the nominal rate (%). Must be a decimal number greater than or equal to 0 and less than 100.',
    )
    .max(
      99.99,
      'Invalid Minimum field area percentage to have the nominal rate (%). Must be a decimal number greater than or equal to 0 and less than 100.',
    ),
  prop_keep: Yup.number()
    .required('Minimum allowed plot area percentage is required.')
    .min(
      0.01,
      'Invalid Minimum allowed plot area percentage (%). Must be a decimal number greater than 0 and less than or equal to 100.',
    )
    .max(
      100,
      'Invalid Minimum allowed plot area percentage (%). Must be a decimal number greater than 0 and less than or equal to 100.',
    ),
  offset: Yup.number()
    .required('Plot placement offset percentage is required.')
    .min(
      0,
      'Invalid Plot placement offset percentage (%). Must be a decimal number greater than or equal to 0 and less than or equal to 100.',
    )
    .max(
      100,
      'Invalid Plot placement offset percentage (%). Must be a decimal number greater than or equal to 0 and less than or equal to 100.',
    ),
  seed: Yup.number().required('Random seed is required.'),
  field_name: Yup.string()
    .required('Field name is required.')
    .matches(
      /^[ A-Za-z0-9_'-]*$/,
      'Invalid field name character(s). Valid field name characters are: a-z, A-Z, 0-9, _, -, apostrophe, space',
    ),
  business_name: Yup.string().matches(
    /^[ A-Za-z0-9_'-]*$/,
    'Invalid operation name character(s). Valid operation name characters are: a-z, A-Z, 0-9, _, -, apostrophe, space',
  ),
  farm_name: Yup.string().matches(
    /^[ A-Za-z0-9_'-]*$/,
    'Invalid farm name character(s). Valid farm name characters are: a-z, A-Z, 0-9, _, -, apostrophe, space',
  ),
  width_ft: Yup.number()
    .required('Plot/strip width is required.')
    .min(0.01, 'Invalid Plot width (ft). Must be a positive decimal number.'),
  length_ft: Yup.number()
    .required('Plot length is required.')
    .min(
      0.01,
      'Invalid Plot length or minimum strip length (ft). Must be a positive decimal number.',
    ),
  buffer_ft: Yup.number()
    .required('Buffer width is required.')
    .min(
      0,
      'Invalid buffer width from field boundary (ft). Must be 0 or a positive decimal number.',
    ),

  treatment_labels: Yup.string()
    .matches(
      /^[ A-Za-z0-9_'#$%.,-]*$/,
      'Invalid treatment rate character(s). Valid treatment rate characters are: a-z, A-Z, 0-9, _, -, #, $, %, apostrophe, period, space.',
    )
    .test({
      name: 'compare-labels-and-rates',
      message:
        'This optional input, if used, must include the same number of commas and labels as there are treatment rates.',
      test: function (value) {
        if (!value || value === 'Use rates') return true;

        const treatmentLabels = splitString(value, ', ');
        const treatmentRates = splitString(
          this.parent.treatment_rates || '',
          ', ',
        );

        return (
          treatmentLabels.length === treatmentRates.length &&
          Boolean(treatmentLabels[treatmentLabels.length - 1])
        );
      },
    }),
  treatment_rates: Yup.string()
    .required('Treatment rates are required')
    .matches(
      /^[ 0-9,.]*$/,
      'This entry must include at least one comma separating at least two treatment rates (positive decimal numbers).',
    )
    .test({
      name: 'test-treatment-rate',
      test: function (value) {
        const { path, createError } = this;

        const treatmentRates = splitString(value || '', ', ');
        let treatmentRatesValid = true;

        for (let i = 0; i < treatmentRates?.length; i++) {
          const rate = Number(treatmentRates[i]);

          if (
            typeof rate !== 'number' ||
            Number.isNaN(rate) ||
            treatmentRates[i] === ''
          ) {
            treatmentRatesValid = false;
            break;
          }
        }

        return (
          treatmentRatesValid ||
          createError({
            path,
            message: `Invalid treatment rate. Each treatment rate must be a single positive decimal number. "${value}" is invalid.`,
          })
        );
      },
    }),
});

export default createTrialSchema;

export const trialBoundarySchema = Yup.object({
  field_boundary_gdf: fileValidator('field boundary'),
  trial_boundary_is_field_boundary: Yup.bool(),
  trial_boundary_gdf: fileValidator('trial boundary', {
    key: 'trial_boundary_is_field_boundary',
    expectedValue: false,
  }),
});

export const testAreasSchema = Yup.object({
  trial_type: Yup.string().required('Trial type is required.'),
  buffer_ft: Yup.number()
    .required('Buffer width is required.')
    .min(
      0,
      'Invalid buffer width from field boundary (ft). Must be 0 or a positive decimal number.',
    ),
  width_ft: Yup.number().when('trial_type', (value, schema) => {
    const type = capitalizeFirstLetter(value);

    return schema
      .required(`${type} width is required.`)
      .min(
        0.01,
        `Invalid ${type} width (ft). Must be a positive decimal number.`,
      );
  }),
  length_ft: Yup.number().when('trial_type', (value, schema) => {
    return value === 'plot'
      ? schema
          .required('Plot length is required.')
          .min(
            0.01,
            'Invalid Plot length (ft). Must be a positive decimal number.',
          )
      : schema;
  }),
});

export const trialIdentificationSchema = Yup.object({
  field_name: Yup.string()
    .required('Field name is required.')
    .matches(
      /^[ A-Za-z0-9_'-]*$/,
      'Invalid field name character(s). Valid field name characters are: a-z, A-Z, 0-9, _, -, apostrophe, space',
    ),
  business_name: Yup.string()
    .required('Business name is required')
    .matches(
      /^[ A-Za-z0-9_'-]*$/,
      'Invalid business name character(s). Valid business name characters are: a-z, A-Z, 0-9, _, -, apostrophe, space',
    ),
  farm_name: Yup.string().matches(
    /^[ A-Za-z0-9_'-]*$/,
    'Invalid farm name character(s). Valid farm name characters are: a-z, A-Z, 0-9, _, -, apostrophe, space',
  ),
  start_date: Yup.date().required('Start date is required.'),
  end_date: Yup.date()
    .required('End date is required.')
    .min(Yup.ref('start_date'), 'End date must be after start date.'),
});

export const treatmentSchema = Yup.object({
  application_units: Yup.string().required('Units is required.'),
  treatment_labels: Yup.string()
    .matches(
      /^[ A-Za-z0-9_'#$%.,-]*$/,
      'Invalid treatment rate character(s). Valid treatment rate characters are: a-z, A-Z, 0-9, _, -, #, $, %, apostrophe, period, space.',
    )
    .test({
      name: 'compare-labels-and-rates',
      message:
        'This optional input, if used, must include the same number of commas and labels as there are treatment rates.',
      test: function (value) {
        if (!value || value === 'Use rates') return true;

        const treatmentLabels = splitString(value, ', ');
        const treatmentRates = splitString(
          this.parent.treatment_rates || '',
          ', ',
        );

        return (
          treatmentLabels.length === treatmentRates.length &&
          Boolean(treatmentLabels[treatmentLabels.length - 1])
        );
      },
    }),
  treatment_rates: Yup.string()
    .required('Treatment rates are required')
    .matches(
      /^[ 0-9,.]*$/,
      'This entry must include at least one comma separating at least two treatment rates (positive decimal numbers).',
    )
    .test({
      name: 'test-treatment-rate',
      test: function (value) {
        const { path, createError } = this;

        const treatmentRates = splitString(value || '', ', ');
        let treatmentRatesValid = true;

        for (let i = 0; i < treatmentRates?.length; i++) {
          const rate = Number(treatmentRates[i]);

          if (
            typeof rate !== 'number' ||
            Number.isNaN(rate) ||
            treatmentRates[i] === ''
          ) {
            treatmentRatesValid = false;
            break;
          }
        }

        return (
          treatmentRatesValid ||
          createError({
            path,
            message: `Invalid treatment rate. Each treatment rate must be a single positive decimal number. "${value}" is invalid.`,
          })
        );
      },
    }),
  nominal_rate: Yup.number()
    .required('Nominal rate is required.')
    .min(0, 'Invalid nominal rate. Must be a positive decimal number.'),
  nominal_label: Yup.string()
    .matches(
      /^[ A-Za-z0-9_'#$%.,-]*$/,
      'Invalid nominal label character(s). Valid nominal label characters are: a-z, A-Z, 0-9, _, -, #, $, %, apostrophe, period, space.',
    )
    // required if treatment labels are provided
    .when('treatment_labels', (value, schema) => {
      if (!value || value === 'Use rates') return schema;
      return schema.required(
        'When treatment labels are provided, nominal label is required.',
      );
    })
    // When the nominal label is provided and matches a treatment label, the nominal rate must match the corresponding treatment rate
    .test({
      name: 'nominal-label-matches-treatment-label',
      message:
        'When the nominal label is provided and matches a treatment label, the nominal rate must match the corresponding treatment rate.',
      test: function (value) {
        const { path, createError } = this;

        const nominalLabel = value || '';
        const nominalRate = Number(this.parent.nominal_rate || 0);
        const treatmentLabels = splitString(
          this.parent.treatment_labels || '',
          ', ',
        );
        const treatmentRates = splitString(
          this.parent.treatment_rates || '',
          ', ',
        );

        if (nominalLabel === '' || !treatmentLabels.includes(nominalLabel))
          return true;

        const treatmentRate = Number(
          treatmentRates[treatmentLabels.indexOf(nominalLabel)],
        );

        return (
          nominalRate === treatmentRate ||
          createError({
            path,
            message: `When the nominal label is provided and matches a treatment label, the nominal rate must match the corresponding treatment rate. "${nominalRate}" is invalid.`,
          })
        );
      },
    }),
});

const trialTypeOptions = ['Planting', 'Pest control', 'Fertilizer'];

export const trialTypeSchema = Yup.object({
  planning_trial_type: Yup.string().required('Trial type is required.'),
  planning_trial_subtype: Yup.string().when(
    'planning_trial_type',
    (value, schema) => {
      if (value && !trialTypeOptions.includes(value)) return schema;

      return value === 'Fertilizer'
        ? schema
        : schema.required('Trial subtype is required.');
    },
  ),
  test_nutrients: Yup.array().when('planning_trial_type', (value, schema) => {
    if (value && !trialTypeOptions.includes(value)) return schema;

    return value === 'Fertilizer'
      ? schema.required('Test nutrient(s) is required.')
      : schema;
  }),
  product: Yup.string().required('Test product(s) is required.'),
  crop: Yup.string().required(),
  variety: Yup.string(),
  trial_equipment: Yup.string().required('Trial equiment is required.'),
});

export const placePlotsSchema = Yup.object({
  prop_keep: Yup.number()
    .required('Minimum acceptable plot area percentage is required.')
    .min(
      0.01,
      'Invalid Minimum acceptable plot area percentage (%). Must be a decimal number greater than 0 and less than or equal to 100.',
    )
    .max(
      100,
      'Invalid Minimum acceptable plot area percentage (%). Must be a decimal number greater than 0 and less than or equal to 100.',
    ),
  nominal_management_prop: Yup.number()
    .required('Minimum field area percentage is required.')
    .min(
      0,
      'Invalid Minimum field area percentage to have the nominal rate (%). Must be a decimal number greater than or equal to 0 and less than 100.',
    )
    .max(
      99.99,
      'Invalid Minimum field area percentage to have the nominal rate (%). Must be a decimal number greater than or equal to 0 and less than 100.',
    ),
  reps_to_remove_from_design: Yup.string()
    .nullable()
    .matches(
      /^[0-9, ]*$/,
      'Replicates to remove must contain only numbers, commas, and spaces.',
    ),
});

export const ablineCreationSchema = Yup.object({
  get_abline_method: Yup.string().required('AB line method is required.'),
  abline_gdf: fileValidator('AB line', {
    key: 'get_abline_method',
    expectedValue: 'upload',
  }),
  abline_heading: Yup.number().when('get_abline_method', (value, schema) => {
    return value === 'heading'
      ? schema
          .required('AB line heading is required.')
          .min(
            0,
            'Invalid AB line heading. Must be a number greater than or equal to 0 and less than 360.',
          )
          .max(
            359.99999999999,
            'Invalid AB line heading. Must be a number greater than or equal to 0 and less than 360.',
          )
      : schema;
  }),
});

export const ablineAdjustmentSchema = Yup.object({
  abline_rotation: Yup.number(),
  abline_adjust: Yup.number(),
});
